module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":[],"excludeOptions":{"separator":"."},"crumbLabelUpdates":[{"pathname":"/diensten","crumbLabel":"Diensten"},{"pathname":"/voor-wie","crumbLabel":"Voor wie"},{"pathname":"/oplossingen","crumbLabel":"Oplossingen"},{"pathname":"/over-ons","crumbLabel":"Over ons"},{"pathname":"/portfolio","crumbLabel":"Portfolio"},{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/vacatures","crumbLabel":"Vacatures"}],"trailingSlashes":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://v2.hq-online.nl/graphql","verbose":true,"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
